import React from 'react';
import { Box, Container, Typography, Grid, Link } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        pt: 3,        // padding-top: 24px
        mt: 'auto',
        backgroundColor: '#eeeeee',  // Define o fundo com a cor #eeeeee
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Seção de links */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Domínios
            </Typography>
            <Link 
              href="https://www.whois.com/whois/" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pesquisa de Domínio Internacional (WhoIs)
            </Link>
            <br />
            <Link 
              href="https://www.registro.br/" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Registro.br (domínios .com.br)
            </Link>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Links Úteis
            </Typography>
            <Link 
              href="https://sebrae.com.br/sites/PortalSebrae/" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              SEBRAE
            </Link>
            <br />
            <Link 
              href="https://chatgpt.com/" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatGPT
            </Link>
          </Grid>

          {/* Seção de redes sociais */}
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Produtividade
            </Typography>
            <Link 
              href="https://www.sitedoed.com.br" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Desenvolvedor
            </Link>
            <br />
            <Link 
              href="https://trello.com/" 
              color="inherit" 
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trello
            </Link>
            <br />
          </Grid>
        </Grid>
      </Container>

      <Box 
        sx={{ 
          backgroundColor: 'black', 
          pt: 2,        // padding-top: 16px
          pb: 2,        // padding-bottom: 16px
          mt: 3, // margem-top: 24 px
          width: '100%' // Garante que o Box ocupe 100% da largura
        }}
      >
        <Typography variant="body2" color="white" textAlign="center"> {/* Texto centralizado e em branco */}
          © {new Date().getFullYear()} Todos os direitos reservados. Os Projetos. CNPJ: 16.808.929/0001-81.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
