import React from 'react';
import { Box, Typography } from '@mui/material';
import BasicGrid from '../components/BasicGrid';

function Desenvolvimento() {
  const gridItems = [
    {
      size: 12,
      content: (
        <Box
          sx={{
            height: { xs: 'auto', md: '50rem' }, // Altura flexível em telas pequenas
            padding: 3,
            textAlign: 'left',
            borderRadius: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Coluna em telas pequenas, linha em grandes
            alignItems: 'flex-start',
          }}
        >
          {/* Box para a imagem */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '40%' }, // 100% em telas menores
              paddingRight: { md: '20px' },
              marginBottom: { xs: 2, md: 0 }, // Espaço entre imagem e texto em telas pequenas
            }}
          >
            <picture>
              <source srcSet="images/notes.jpeg" type="image/jpeg" />
              <img
                src="images/bola.jpg"
                alt="Desenvolver Projeto de Software"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                }}
              />
            </picture>
          </Box>

          {/* Box para o texto */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '60%' }, // Largura total em telas pequenas
            }}
          >
            <Typography variant="h4" gutterBottom>
              Desenvolvimento
            </Typography>
            <Typography
              sx={{
                textIndent: '30px',
                fontSize: { xs: '16px', md: '18px' }, // Fonte menor em telas pequenas
                textAlign: 'justify',
                marginBottom: '5px',
              }}
            >
              Desenvolvemos soluções digitais eficientes, escaláveis e altamente funcionais, sempre com foco na excelência técnica e na entrega da melhor experiência possível ao usuário final. Com base sólida tanto em front-end, quanto em back-end, estamos atualizados com as últimas tendências e melhores práticas do desenvolvimento web. Utilizamos frameworks reconhecidos como React, Vue.js, e Angular para criar interfaces dinâmicas e responsivas, enquanto no back-end, trabalhamos com tecnologias robustas como Node.js, Python/Django, e PHP/Laravel, JAVA/Spring Boot, sempre garantindo que os sistemas sejam integrados de forma fluida com bancos de dados relacionais (SQL) e NoSQL.
            </Typography>
            <Typography
              sx={{
                textIndent: '30px',
                fontSize: { xs: '16px', md: '18px' },
                textAlign: 'justify',
                marginBottom: '5px',
              }}
            >
              Acreditamos no poder da colaboração e na importância de uma comunicação clara e transparente com nossos clientes. Cada projeto é uma oportunidade para transformar ideias em soluções digitais de alto impacto. Do design inicial até a implementação e manutenção, buscamos não apenas atender às necessidades imediatas, mas também oferecer soluções escaláveis que possam acompanhar o crescimento do seu negócio.
            </Typography>
            <Typography
              sx={{
                textIndent: '30px',
                fontSize: { xs: '16px', md: '18px' },
                textAlign: 'justify',
                marginBottom: '5px',
              }}
            >
              Em sites empresariais, e-commerces, ou aplicações web personalizadas, nossa abordagem é sempre pautada na qualidade, segurança e otimização de desempenho. Nosso compromisso é com o sucesso do seu projeto e a entrega dos melhores resultados. Vamos transformar suas ideias em realidade digital!
            </Typography>
          </Box>
        </Box>
      )
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pl: 2,
        pr: 2,
      }}
    >
      {/* Box da Imagem, separado do conteúdo principal */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'black',
          padding: 3,
        }}
      >
        <picture>
          <source srcSet="images/qualidade-de-software-1024x587.jpg" type="image/jpeg" />
          <img
            src="images/bola.jpg"
            alt="Desenvolver Projeto de Software"
            style={{
              maxWidth: '800px',
              width: '100%',
              height: 'auto',
            }}
          />
        </picture>
      </Box>

      {/* Conteúdo Principal */}
      <BasicGrid gridItems={gridItems} />
    </Box>
  );
}

export default Desenvolvimento;
