import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

function Contato() {
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    assunto: '',
    mensagem: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepara os dados para o formato application/x-www-form-urlencoded
    const formBody = new URLSearchParams(formData);

    // Enviar os dados para o backend PHP via fetch
    const response = await fetch('/backend/send-email.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: formBody,
    });

    const result = await response.json();

    if (result.success) {
      alert('Mensagem enviada com sucesso!');
    } else {
      alert('Erro ao enviar a mensagem: ' + result.message);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 3, minHeight: '100vh', margin: '0 auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center', textAlign: 'left', padding: 3, maxWidth: '600px', width: '100%', boxSizing: 'border-box' }}>
        <Typography variant="body1" gutterBottom sx={{ textIndent: '30px' }}>
          Agradecemos a gentileza de nos contactar. Por favor, preencha os dados abaixo e responderemos o mais rápido possível! Até logo!
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'center', padding: 3, maxWidth: '600px', width: '100%', boxSizing: 'border-box' }}>
        <Typography variant="h4" gutterBottom>Contato</Typography>

        <TextField fullWidth label="Nome" variant="outlined" margin="normal" name="nome" value={formData.nome} onChange={handleChange} required />
        <TextField fullWidth label="E-mail" type="email" variant="outlined" margin="normal" name="email" value={formData.email} onChange={handleChange} required />
        <TextField fullWidth label="Assunto" variant="outlined" margin="normal" name="assunto" value={formData.assunto} onChange={handleChange} required />
        <TextField fullWidth label="Mensagem" variant="outlined" margin="normal" name="mensagem" value={formData.mensagem} onChange={handleChange} required multiline rows={4} />

        <Button variant="contained" sx={{ marginTop: 2, width: '100%', backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'gray' } }} onClick={handleSubmit}>
          Enviar
        </Button>
      </Box>

      <ul style={{ listStyle: 'none', paddingLeft: 0, textAlign: 'left', marginTop: '20px' }}>
        <li>* Campos obrigatórios</li>
        <li>O WhatsApp é opcional, mas pode agilizar muito nossa comunicação!</li>
      </ul>
    </Box>
  );
}

export default Contato;
