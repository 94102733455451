// src/components/Carousel.js

import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Box, Typography } from '@mui/material';

function CarouselComponent({ items }) {
    return (
        <Carousel 
            interval={3000} 
            indicators={false} 
            animation="slide" 
            navButtonsAlwaysVisible
        >
            {items.map((item, index) => (
                <CarouselItem key={index} item={item} />
            ))}
        </Carousel>
    );
}

function CarouselItem({ item }) {
    return (
        <Paper 
            style={{
                textAlign: 'center',
                backgroundColor: '#f5f5f5'
            }}
        >
            {item.image && (
                <img 
                    src={item.image} 
                    alt={item.title} 
                    style={{
                        width: '100%',
                        height: 'auto',      // Mantém a proporção original
                        objectFit: 'cover'    // Adapta o conteúdo se necessário
                    }}
                />
            )}
    <Box
      sx={{
        display: 'flex',
        alignItems: 'baseline', // Alinha o título e a descrição verticalmente
        justifyContent: 'center', // Alinha os itens horizontalmente ao centro
        gap: 1,               // Espaço entre os elementos
      }}
    >
        {/*
        <Typography variant="h5" component="h2">
            {item.title}
        </Typography>
        <Typography variant="body1" component="span">
            {item.description}
        </Typography>
        */}
    </Box>
        </Paper>
    );
}

export default CarouselComponent;
