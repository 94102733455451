import React from 'react';
import { Box, Typography } from '@mui/material';
import BasicGrid from '../components/BasicGrid';

function Solucoes() {
  const gridItems = [
    {
      size: 12,
      content: (
        <Box
          sx={{
            height: { xs: 'auto',  },
            padding: 3,
            textAlign: 'left',
            borderRadius: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '60%' },
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            <Typography variant="h4" gutterBottom>
              Soluções
            </Typography>
            <Typography
              sx={{
                textIndent: '30px',
                fontSize: { xs: '16px', md: '18px' },
                textAlign: 'justify',
                marginBottom: 2,
              }}
            >
              Se você tem uma ideia e força de vontade suficiente para colocá-la em prática, nós podemos desenvolver um projeto de sucesso e transformar seus sonhos em realidade! Sejam eles pequenos ou grandes, a internet oferece a chance de realizá-los. E, com nossa expertise, nós podemos tornar o seu caminho mais fácil e te ajudar a alcançar seus objetivos mais rápido. Abaixo algumas das opções que oferecemos:
            </Typography>

            {/** Lista de soluções */}
            <Typography variant="h6" gutterBottom>
              Sites Institucionais
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Personalizados para empresas, organizações e profissionais que buscam estabelecer uma presença online sólida e profissional, projetados para transmitir a identidade da marca, fornecer informações claras e facilitar a navegação.
            </Typography>

            <Typography variant="h6" gutterBottom>
              E-commerces
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Plataformas de comércio eletrônico completas, para negociar produtos ou serviços de maneira eficiente e segura. Trabalhamos com soluções como Shopify, WooCommerce, Magento, além de plataformas personalizadas, sempre com ênfase na usabilidade e na integração com sistemas de pagamento, logística e gestão de estoque.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Web Apps e Aplicações Personalizadas
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Aplicações web que atendem a necessidades específicas, como plataformas de gerenciamento, sistemas de automação, dashboards interativos e integrações com outras ferramentas corporativas.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Landing Pages e Páginas de Vendas
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Otimizadas para conversão, com foco em atrair e engajar visitantes para ações específicas, como inscrições, downloads ou compras. Essas páginas são criadas com design intuitivo e estratégias de SEO e copywriting.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Blogs e Portais de Conteúdo
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Oferecemos aos nossos clientes uma plataforma de fácil gestão de posts, categorias e interações com o público. Utilizamos WordPress, Ghost ou plataformas customizadas.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Sistemas de Gestão de Conteúdo (CMS)
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              CMS personalizados que permitem aos nossos clientes gerenciar seus sites de maneira fácil e intuitiva, sem necessidade de conhecimentos técnicos.
            </Typography>

            <Typography variant="h6" gutterBottom>
              SEO (Otimização para Mecanismos de Busca)
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Para melhorar a visibilidade e o ranking nos motores de busca, implementando estratégias de otimização.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Desenvolvimento Mobile (Responsivo e Apps)
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Garantimos que todos os sites que criamos sejam totalmente responsivos e adaptáveis a qualquer tamanho de tela.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Integrações e APIs
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Integrações personalizadas entre diferentes sistemas, plataformas de pagamento, ERPs, CRMs e outras soluções corporativas.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Hospedagem e Manutenção Web
            </Typography>
            <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, marginBottom: 2 }}>
              Na nuvem, ou de baixo custo, para garantir que os sites de nossos clientes tenham a performance e a segurança adequadas.
            </Typography>
          </Box>

          <Box
            sx={{
              width: { xs: '100%', md: '40%' },
              paddingLeft: { xs: 0, md: 3 },
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <picture>
              <source srcSet="images/notes.jpeg" type="image/jpeg" />
              <img
                src="images/bola.jpg"
                alt="Desenvolver Projeto de Software"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                }}
              />
            </picture>
          </Box>
        </Box>
      )
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <BasicGrid gridItems={gridItems} />
    </Box>
  );
}

export default Solucoes;
