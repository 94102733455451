import './App.css';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import CarouselComponent from './components/Carousel';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Desenvolvimento from './pages/Desenvolvimento';
import Solucoes from './pages/Solucoes';
import Contato from './pages/Contato';
import Footer from './components/Footer';

function AppContent() {
  const location = useLocation();
  
  const items = [
    {
        title: 'Recriando a Relidade',
        description: 'Novidades, em breve!',
        image: '/images/banner1.jpg'
    },
    {
      title: 'WEB 2.0',
      description: 'Padrão de Projetos!',
      image: '/images/web2.0.jpg'
    },
    {
      title: 'Recriando a Relidade',
      description: 'Novidades, em breve!',
      image: '/images/banner3.jpg'
  },
  ];

  return (
    <div className="App">
      <ResponsiveAppBar />
      
      {/* Renderiza o carrossel somente se a rota for "/" */}
      {location.pathname === '/' && <CarouselComponent items={items} />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/desenvolvimento" element={<Desenvolvimento />} />
        <Route path="/solucoes" element={<Solucoes />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
      <Footer/>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
