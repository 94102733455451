import React from 'react';
import { Box, Typography } from '@mui/material';
import BasicGrid from '../components/BasicGrid';

function Home() {
  const gridItems = [
    { 
      size: 12, 
      content: (
        <Box
          sx={{
            height: { xs: 'auto', md: '50rem' }, // Altura ajustável para telas menores
            padding: 3,
            textAlign: 'left',
            borderRadius: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Coluna em telas pequenas, linha em maiores
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '60%' }, // Texto ocupa largura total em telas menores
              marginBottom: { xs: 2, md: 0 }, // Espaçamento inferior em telas menores
            }}
          >
            <Typography variant="h4" gutterBottom>
              Home
            </Typography>
            <Typography 
              sx={{ 
                textIndent: '30px', 
                fontSize: { xs: '16px', md: '18px' }, // Fonte menor em telas pequenas
                textAlign: 'justify',
                marginBottom: '5px',
              }}
            >
              Apps e Sites Responsivos, compatíveis com todos os tipos de dispositivos. 
              Inteligência Artificial, Big Data, Computação na Nuvem e Segurança na Rede.
              Web Semântica, Otimização Orgânica e Tráfego Pago.
              T.I.C - Tecnologias da Informação e da Comunicação e os 
              melhores padrões de desenvolvimento a alguns cliques de distância.
              Esse é o nosso trabalho e pode ser o seu sucesso!
            </Typography>
            <Typography 
              sx={{ 
                textIndent: '30px', 
                fontSize: { xs: '16px', md: '18px' },
                textAlign: 'justify',
                marginBottom: '5px',
              }}
            >
              Há mais de 12 anos no mercado web, podemos dizer que vimos
              muitas coisas surpeendentes acontecerem,
              inúmeros casos de sucesso e poucas, pessoas ou empresas,
              aproveitando todo o potencial da rede. Sabemos que não é
              falta de tentativas, muito menos de boa vontade, o que 
              leva a resultados insatisfatórios. Tampouco, embora haja
              muita gente com sorte grande, é o acaso que leva ao sucesso.
              Porque boas ideias, sozinhas, não garantem bons resultados. 
              Elas precisam ser acompanhadas, minuciosamente planejadas
              e, acima de tudo, bem executadas para terem o melhor resultado
              possível. E é pra isso que estamos aqui, pra caminharmos juntos
              até alcançarmos o, nosso, tão almejado sucesso!
            </Typography>
          </Box>
    
          <Box
            sx={{
              width: { xs: '100%', md: '40%' }, // A imagem ocupa toda a largura em telas pequenas
              paddingLeft: { xs: 0, md: 3 }, // Padding somente em telas maiores
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' }, // Centraliza em telas pequenas
            }}
          >
            <picture>
              <source srcSet="images/notes.jpeg" type="image/jpeg" />
              <img 
                src="images/bola.jpg" 
                alt="Desenvolver Projeto de Software" 
                style={{ 
                  width: '100%', // A imagem se ajusta ao container
                  height: 'auto', 
                  borderRadius: '8px',
                }} 
              />
            </picture>
          </Box>
        </Box>
      )
    },
  ];
  
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
      }}
    >
      <BasicGrid gridItems={gridItems} />
    </Box>
  );
}

export default Home;
